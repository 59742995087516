import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import Header from "../Components/Header";
import "../css/terms.css";

const Closed = () => {
  const [data, setData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  // Fetch website settings
  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}settings/data`);
      const data = await response.json();
      setWebsiteSettings(data);
    } catch (error) {
      console.error("Error fetching website settings:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="leftContainer">
        <Header />
        <div
          className="mt-5 py-5 px-4"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "400px",
          }}
        >
          <div className="text-center">
            <h1 style={{ fontSize: "2rem", color: "#333" }}>
              Currently, the Game is Closed
            </h1>
            <p style={{ fontSize: "1.2rem", color: "#666" }}>
              Please check back later
            </p>
            <img
              style={{
                width: "50%",
                maxWidth: "300px",
                margin: "20px 0",
                opacity: 0.8,
              }}
              src="/Images/close.png"
              alt="Game Closed"
            />
          </div>
          <Link
            className="btn btn-primary mt-3"
            style={{ alignSelf: "center" }}
            to={"/"}
          >
            Reload Page
          </Link>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Closed;
